import { useRef, useState, UIEvent } from 'react';
import { Link } from 'react-router-dom';
import { TypoCallout, TypoHeading } from './Typography';
import DefaultThumbnail from '@/assets/video-thumbnail.png';
import { ArrowLeft, ArrowRight } from '@/components/Icons.tsx';

const VideoCollectionsList = ({ list }: VideoCollectionsListProps) => (
  <div className="grid-cols-1 divide-y divide-neutral-canvas2 divide-opacity-10">
    {
      list.map((collection) =>
        <Collection key={collection.id} {...collection} />
      )
    }
  </div>
);

const Collection = ({ name, items }: CollectionProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const checkScrollPosition = (event: UIEvent<HTMLDivElement>) => {
    const { scrollLeft, scrollWidth, clientWidth } = event.currentTarget;
    setIsAtStart(scrollLeft === 0);
    setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -scrollContainerRef.current.clientWidth / 3, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: scrollContainerRef.current.clientWidth / 3, behavior: 'smooth' });
    }
  };

  return (
    <div className="flex flex-col py-14 gap-8 first:pt-0 last:pb-0">
      <div className="flex items-center justify-between">
        <TypoHeading className="text-neutral-text-strong">{name}</TypoHeading>
        <div className="flex">
          <button
            className="bg-green-500 text-primary p-2 disabled:text-shade-30"
            disabled={isAtStart}
            onClick={scrollLeft}>
            <ArrowLeft width={24} height={24} />
          </button>
          <button
            className="bg-green-500 text-primary p-2 disabled:text-shade-30"
            disabled={isAtEnd}
            onClick={scrollRight}>
            <ArrowRight width={24} height={24} />
          </button>
        </div>
      </div>
      <div className="flex gap-x-6 overflow-hidden" ref={scrollContainerRef} onScroll={checkScrollPosition}>
        {
          items?.map((item) =>
            <CollectionItem key={item.id} {...item} />
          )
        }
      </div>
    </div>
  );
};

export const CollectionItem = ({
  id,
  type,
  name,
  image,
  className = 'w-full sm:w-1/2 lg:w-2/5',
}: CollectionItemProps & { className?: string }) => (
  <Link className={`flex flex-col gap-3 flex-shrink-0 ${className}`} to={`/video-tutorials/${type}/${id}`}>
    <img src={image || DefaultThumbnail} alt="Video Thumbnail" className="rounded-xl drop-shadow w-full" />
    <TypoCallout className="px-2 text-neutral-text-soft font-medium leading-5">{name}</TypoCallout>
  </Link>
);

export interface CollectionItemProps extends CollectionProps {
  type: 'collection' | 'video';
}

export interface CollectionProps {
  id: number;
  name: string;
  image?: string | null;
  items?: Array<CollectionItemProps>;
}

export interface VideoCollectionsListProps {
  list: Array<CollectionProps>
}

export default VideoCollectionsList;
