import { ReactNode } from 'react';
import { Tab } from '@headlessui/react';

interface ITabsProps {
  defaultIndex?: number;
  barSlot?: ReactNode;
  barStyle?: string;
  containerStyle?: string;
  tabs: Array<{
    title: string;
    content: ReactNode;
  }>
}

const Tabs = ({ defaultIndex = 0, barSlot, tabs, barStyle = '', containerStyle = '' }: ITabsProps) => (
  <Tab.Group defaultIndex={defaultIndex}>
    <div className={`flex justify-between items-center gap-2 ${barStyle}`}>
      <Tab.List className="flex gap-x-2 py-1.5">
        {tabs.map((tab, idx) => (
          <Tab
            key={idx}
            className={`outline-0
            px-4 py-1.5 rounded-md font-medium text-callout text-neutral-text
            hover:bg-neutral-canvas2-hover hover:text-neutral-canvas2-hover-text
            ui-selected:bg-neutral-canvas2 ui-selected:text-neutral-canvas2-text
          `}
          >
            {tab.title}
          </Tab>
        ))}
      </Tab.List>
      {barSlot}
    </div>
    <Tab.Panels className={`mt-4 text-left ${containerStyle}`}>
      {tabs.map((tab, idx) => (
        <Tab.Panel
          key={idx}
        >
          {tab.content}
        </Tab.Panel>
      ))}
    </Tab.Panels>
  </Tab.Group>
);



export default Tabs;
