import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { TypeOptions } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import TestTabs from '@/components/TestTabs.tsx';
import Separator from '@/components/Separator.tsx';
import TestList, { TestListProps } from '@/components/TestList.tsx';
import { TypoBody, TypoHeading, TypoHeading2 } from '@/components/Typography.tsx';
import notify, { dismissAllNotifications } from '@/utils/notifications.tsx';
import TextField from '@/components/TextField.tsx';
import Button from '@/components/Button.tsx';
import MissingTests from '@/components/MissingTests.tsx';
import PURCHASE_OPTIONS_QUERY from '@/graphql/queries/purchase-options.graphql';
import AVAILABLE_EXAMS_QUERY from '@/graphql/queries/available-exams.graphql';
import CLIENT_EXAMS_QUERY from '@/graphql/queries/client-exams-short.graphql';
import ORDERS_QUERY from '@/graphql/queries/user-orders.graphql';
import { toJSON } from '@/utils/helpers.tsx';

const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = Number(searchParams.get('order_id'));
  const { data } = useQuery(ORDERS_QUERY, { skip: !orderId });
  const orders = useMemo(() => data?.user?.orders ?? [], [data]);
  const currentOrder = orders.find((o: { orderId: number }) => o.orderId === orderId);

  useEffect(() => {
    let timeoutId: any;

    if (currentOrder) {
      const { product } = currentOrder?.productPrice ?? {};
      const { type, message } = getOrderStatus(currentOrder.status);

      notify(product.name, message, { type });
      timeoutId = setTimeout(() => setSearchParams(''), 3000);
    }

    return () => {
      if (currentOrder) dismissAllNotifications();
      clearTimeout(timeoutId);
    };
  }, [currentOrder, orderId, setSearchParams]);

  return (
    <>
      <TypoHeading2 className="text-neutral-text-strong font-medium px-10 pt-6 pb-4">
        Dashboard
      </TypoHeading2>
      <TestSection />
    </>
  );
};

const TestSection = () => {
  const { exams, examsLoading, products, productsLoading, reFetchProducts } = useTestsData();
  const setExams = exams.filter(e => e.subtitle === 'SET');
  const fullExams = exams.filter(e => e.subtitle !== 'SET');

  const onCodeUpdate = useCallback(async (code: string) => {
    await reFetchProducts({ code });
  }, [reFetchProducts]);

  return (
    <div className="w-full bg-neutral2 text-neutral2-text-soft rounded px-10 pt-4 pb-8">
      <TypoHeading className="text-neutral-text font-medium py-2">
        Practice Makes Perfect
      </TypoHeading>
      <TypoBody className="mb-8">
        Take one of your full-length practice tests or work through one of your SATPrac packs
        and take a step towards perfection!
      </TypoBody>

      {exams.length === 0 && <MissingTests text="No tests available." />}
      {fullExams.length > 0 && (
        <div className="mt-4">
          <TypoBody className="text-neutral-text font-medium py-2">Full-length Exams</TypoBody>
          <TestList loading={examsLoading} list={fullExams} />
        </div>
      )}
      {setExams.length > 0 && (
        <div className="mt-4">
          <TypoBody className="text-neutral-text font-medium py-2">Question Sets and SATPrac Packs</TypoBody>
          <TestList loading={examsLoading} list={setExams}/>
        </div>
      )}
      <Separator className="my-14" />
      <TypoHeading className="text-neutral-text font-medium py-2">
        Exclusive Tests and SATPrac Packs Just For You
      </TypoHeading>
      <TypoBody className="mb-8">
        Grab our specially curated bundles and get a head start on your test prep journey.
      </TypoBody>
      <TestList loading={examsLoading || productsLoading} list={products} withPrice />
      {Boolean(products.length) &&
        <div className="mt-8">
          <TypoHeading className="text-neutral-text font-medium py-2">
            Have a Promo Code?
          </TypoHeading>
          <TypoBody className="mb-8">
            Enter your unique code to avail exclusive discounts and make your test prep journey even more rewarding.
          </TypoBody>
          <PromoCodeForm onSubmit={onCodeUpdate} />
        </div>
      }
      <Separator className="my-14" />
      <TestTabs />
    </div>
  );
};

const PromoCodeForm = ({ onSubmit }: { onSubmit: (code: string) => Promise<void> }) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset
  } = useForm({ defaultValues: { code: '' }, resolver: yupResolver(PromoCodeSchema) });

  const submitCallback = useCallback(async ({ code = '' }) => {
    clearErrors();
    setLoading(true);
    await onSubmit(code);
    setLoading(false);
    reset();
  }, [onSubmit, clearErrors, reset]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit(submitCallback)}
      className="flex items-center gap-4 justify-between p-4 rounded-xl bg-neutral mt-4"
    >
      <TextField
        {...register('code')}
        type="text"
        placeholder="#SK123"
        label="Enter your Promo Code"
        error={Boolean(errors?.code)}
        className="flex-1"
      />
      <Button className="w-32 mt-7" color="info" type="submit" loading={loading}>Apply</Button>
    </form>
  );
};

const useTestsData = () => {
  const { data: examsData, loading: examsLoading } = useQuery(AVAILABLE_EXAMS_QUERY);
  const { data: clientExamsData, loading: clientExamsLoading } = useQuery(CLIENT_EXAMS_QUERY);
  const { data: productsData, loading: productsLoading, refetch: reFetchProducts } = useQuery(PURCHASE_OPTIONS_QUERY);

  const clientExams = useMemo(() => clientExamsData?.clientExams as any[] ?? [], [clientExamsData?.clientExams]);
  const exams: TestListProps['list'] = useMemo(() =>
    (examsData?.exams ?? []).map((exam: ExamProps) => {
      const taken = clientExams.find(e => e.exam.examId === exam.examId);

      return {
        id: exam.examId,
        title: exam.name,
        subtitle: exam.examType.name,
        isActive: taken && !taken.isCompleted,
        isCompleted: taken?.isCompleted,
      };
    }), [examsData?.exams, clientExams]);

  const products: TestListProps['list'] = useMemo(() =>
    (productsData?.purchaseOptions ?? [])
      // Todo: update filtering to exclude purchased items.
      // We can use order information and the nested price.productPriceId to filter out purchased items.
      // But also a backend update might be planned to include this information in the query.
      .filter((product: ProductProps) => product?.price?.productPriceId)
      .map((product: ProductProps) => {
        const examsByType = product.exams.reduce((list: any[], exam: ProductProps['exams'][0]) => {
          const current = list.find(i => i.type === exam.examType.name);

          if (current) current.exams.push(exam);
          else list.push({ type: exam.examType.name, exams: [exam] });

          return list;
        }, []);

        const types = examsByType.map(({ exams, type }) => {
          const typeText = type === 'SET' ? 'SATPrac Pack' : `${type} Test`;

          return `${exams.length} ${typeText}${exams.length > 1 ? 's' : ''}`;
        });

        return {
          id: product.productId,
          title: product.name,
          description: toJSON(product.description),
          subtitle: types.join(' | '),
          price: product?.price?.price ?? 0,
          priceId: product.price.productPriceId,
        };
      }), [productsData?.purchaseOptions]);

  const uniqueExams = exams.reduce((acc, current) => {
    if (!acc.find((exam) => exam.id === current.id)) {
      acc.push(current);
    }

    return acc;
  }, [] as TestListProps['list']);

  return {
    exams: uniqueExams,
    examsLoading: examsLoading || clientExamsLoading,
    products,
    productsLoading,
    reFetchProducts,
  };
};

const PromoCodeSchema = yup.object().shape({
  code: yup.string().max(45),
});

interface ExamProps {
  examId: number;
  name: string;
  examType: { name: string; }
}

interface ProductProps {
  productId: number;
  name: string;
  description: string;
  price: { productPriceId: number; price: number; }
  exams: {
    examId: number;
    name: string;
    examType: { name: string }
  }[];
}

enum OrderStatus {
  FAILED = 'FAILED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED'
}

const getOrderStatus = (status: OrderStatus) => {
  let type: TypeOptions;
  let message: string;

  switch (status) {
  case OrderStatus.PAID:
    type = 'success';
    message = 'Product purchased successfully!';
    break;
  case OrderStatus.FAILED:
    type = 'error';
    message = 'Order failed!';
    break;
  case OrderStatus.PENDING:
    type = 'warning';
    message = 'Order is pending!';
    break;
  case OrderStatus.REFUNDED:
    type = 'info';
    message = 'Order refunded!';
    break;

  default:
    type = 'default';
    message = `Order status: ${status}`;
    break;
  }

  return { type, message };
};

export default Dashboard;
