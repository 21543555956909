import { useMemo } from 'react';
import { BottomBar, TopBar } from '@/components/Navigation';
import Timer from '@/components/TestTimer.tsx';
import QuestionSection from '@/pages/TestPage/QuestionSection.tsx';
import AnswersSection from '@/pages/TestPage/AnswersSection.tsx';
import AnswersReviewSection from '@/pages/TestPage/AnswersReviewSection.tsx';
import MathReference from '@/components/MathReference.tsx';
import Calculator from '@/components/Calculator.tsx';
import { ComponentProps } from '@/types/component.types.ts';
import { DataProp } from 'editorjs-blocks-react-renderer';

interface TestScreenProps extends ComponentProps<DataProp> {
  question?: number;
  isPreview?: boolean;
  onQuestionChange: (q: number) => void;
  onCompleteExam: (breakAfter?: number) => void;
}

const TestScreen = ({
  question = 1,
  isPreview = false,
  onQuestionChange,
  onCompleteExam,
  duration,
  durationElapsed,
  name,
  questions,
  directions,
  breakAfter,
  componentType,
}: TestScreenProps) => {
  const currentQuestion = useMemo(() =>
    questions[question - 1]
  , [questions, question]);

  return (
    <>
      <TopBar
        isPreview={isPreview}
        title={name}
        rightSlot={
          <>
            <MathReference />
            {/*`key` is used to reset the state of the calculator when question changes*/}
            <Calculator key={question} />
          </>
        }
      >
        {
          componentType !== 'SET' &&
          <Timer duration={duration} elapsed={durationElapsed} onComplete={onCompleteExam} readOnly={isPreview} />
        }
      </TopBar>
      <div className="flex flex-1 min-h-0">
        {
          currentQuestion?.question ? (
            <>
              <QuestionSection
                categories={currentQuestion.categories}
                question={currentQuestion.question}
                directions={directions}
                explanation={currentQuestion.explanation}
                readOnly={isPreview}
                componentType={componentType}
              />

              <div className="bg-[#44413C] w-1 my-10 rounded-md" />

              {
                isPreview
                  ? <AnswersReviewSection currentQuestion={currentQuestion} />
                  : <AnswersSection currentQuestion={currentQuestion} />
              }
            </>
          ) : (
            <strong className="self-center flex-1 text-center">
              Something is wrong with the question!
            </strong>
          )
        }
      </div>
      <BottomBar
        title={`${name} Questions`}
        isPreview={isPreview}
        questions={questions}
        currentQuestion={question}
        onPrevQuestion={() => onQuestionChange(question - 1)}
        onNextQuestion={() => onQuestionChange(question + 1)}
        onQuestionSelect={onQuestionChange}
        onCompleteExam={() => onCompleteExam(breakAfter)}
      />
    </>
  );
};

export default TestScreen;
