import { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet,
  Link,
} from 'react-router-dom';
import { AuthProvider } from '@/AuthProvider.tsx';
import SignIn from '@/pages/SignIn.tsx';
import Dashboard from '@/pages/Dashboard.tsx';
import useAuth from '@/hooks/useAuth.ts';
import PasswordReset from '@/pages/PasswordReset';
import VerifyPasswordReset from '@/pages/VerifyPasswordReset';
import NavContainer from '@/components/Navigation/NavContainer.tsx';
import { TypoHeading } from '@/components/Typography.tsx';
import Logo from '@/assets/logo.svg';
import Sidebar from '@/components/Sidebar.tsx';
import SignUp from '@/pages/SignUp.tsx';
import AllTests from '@/pages/AllTests';
import TestReviewInit from '@/pages/TestReviewInit.tsx';
import TestIntro from '@/pages/TestIntro.tsx';
import BreakTime from '@/pages/BreakTime.tsx';
import VerifyEmail from '@/pages/VerifyEmail.tsx';
import Profile from '@/pages/Profile';
import { TestActivePage, TestReviewPage } from '@/pages/TestPage';
import { Home } from '@/pages/Home';
import PrivacyPolicy from '@/pages/PrivacyPolicy.tsx';
import Terms from '@/pages/Terms.tsx';
import { VideoTutorials, VideoCollection, Video } from '@/pages/VideoTutorials';
import Contact from '@/pages/Contact.tsx';

const App = () => (
  <AuthProvider>
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route element={<DefaultLayout />}>
        <Route index path="/verify-email" element={<VerifyEmail />} />
        <Route index path="/terms" element={<Terms />} />
        <Route index path="/privacy-policy" element={<PrivacyPolicy />} />
      </Route>
      <Route element={<AuthlessLayout />}>
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/verify-password-reset" element={<VerifyPasswordReset />} />
        <Route index path="/sign-in" element={<SignIn />} />
      </Route>
      <Route element={<AuthenticatedLayout main />}>
        <Route path="/profile" element={<Profile />} />
        <Route
          index
          path="/dashboard"
          element={<Dashboard />}
        />
        <Route
          path="/dashboard/tests"
          element={<AllTests />}
        />
        <Route
          path="/video-tutorials"
          element={<VideoTutorials />}
        />
        <Route
          path="/video-tutorials/collection/:collectionId"
          element={<VideoCollection />}
        />
        <Route
          path="/video-tutorials/video/:videoId"
          element={<Video />}
        />
        <Route
          path="/contact"
          element={<Contact />}
        />
      </Route>
      <Route element={<AuthenticatedLayout />}>
        <Route
          path="/tests/:examId"
          element={<TestIntro />}
        />
        <Route
          path="/tests/:examId/break"
          element={<BreakTime />}
        />
        <Route
          path="/tests/:examId/:examComponentId/:question?"
          element={<TestActivePage />}
        />
        <Route
          path="/review/:examId/:clientExamId"
          element={<TestReviewInit />}
        />
        <Route
          path="/review/:examId/:clientExamId/:examComponentId/:question?"
          element={<TestReviewPage />}
        />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </AuthProvider>
);

const AuthenticatedLayout = ({ main = false }: { main?: boolean }) => {
  const auth = useAuth();
  const location = useLocation();
  const className = 'flex flex-col justify-stretch h-full bg-neutral';

  if (!auth.clientId) {
    // Redirect them to the /sign-in page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  if (main) {
    return (
      <main className={`${className} items-center overflow-auto pb-8 pl-100 pr-6 xl:px-6`}>
        <section className="flex flex-col w-full max-w-[40rem]">
          <Outlet />
        </section>
        <Sidebar />
      </main>
    );
  }

  return (
    <main className={className}>
      <Outlet />
    </main>
  );
};

const AuthlessLayout = () => {
  const auth = useAuth();
  const { pathname } = useLocation();
  const to = pathname == '/verify-password-reset' ? '/profile' : '/dashboard';

  useEffect(() => {
    document.body.classList.add('auth');

    return () => {
      document.body.classList.remove('auth');
    };
  }, []);

  if (auth.clientId) return <Navigate to={to} replace />;

  return <DefaultLayout />;
};

const DefaultLayout = () => (
  <main className="flex flex-col justify-stretch h-full bg-neutral">
    <NavContainer>
      <Link to="/" className="inline-flex items-center gap-1.5">
        <img className="h-12 mr-2.5" src={Logo} alt="SATPrac logo" />
        <TypoHeading variant="secondary">SAT</TypoHeading>
        <TypoHeading>Prac</TypoHeading>
      </Link>
    </NavContainer>
    <section className="flex flex-1 justify-center items-center overflow-auto px-4">
      <Outlet />
    </section>
  </main>
);

export default App;
