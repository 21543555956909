import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import Loader from '@/components/Loader.tsx';
import { TypoBody, TypoHeading2 } from '@/components/Typography.tsx';
import { DropShipping } from '@/components/Icons.tsx';
import Button from '@/components/Button.tsx';
import CollectionsList, { CollectionProps } from '@/components/VideoCollectionsList.tsx';
import VIDEO_TUTORIALS_QUERY from '@/graphql/queries/video-tutorials.graphql';

const VideoTutorials = () => {
  const { list, loading } = useVideoCollections();

  if (loading) return <Loader />;

  return (
    <>
      <TypoHeading2 className="text-neutral-text-strong font-medium px-10 pt-6 pb-4">
        Video Tutorials
      </TypoHeading2>
      <div className="w-full bg-neutral2 text-neutral2-text-soft rounded px-10 pt-4 pb-8">
        {list.length === 0 && <EmptyState />}
        {list.length > 0 && <CollectionsList list={list} />}
      </div>
    </>
  );
};

const EmptyState = () => (
  <div className="flex flex-col items-center justify-center gap-4 py-28">
    <div className="flex flex-col gap-12">
      <DropShipping className="px-3 py-0.5" />
      <TypoBody>You have no packages yet.</TypoBody>
    </div>
    {/*Todo: Point this to packages when the page is implemented*/}
    <Link to="/dashboard">
      <Button color="success">Add Video Tutorials</Button>
    </Link>
  </div>
);

const useVideoCollections = (): { list: CollectionProps[], loading: boolean } => {
  const { data, loading } = useQuery<QueryResult>(VIDEO_TUTORIALS_QUERY);

  const list: CollectionProps[] = useMemo(() => (data?.user.videoTypes ?? []).map(topCollection => ({
    id: topCollection.videoTypeId,
    name: topCollection.name,
    type: 'collection',
    items: topCollection.subtypes.map(subCollection => ({
      id: subCollection.videoSubtypeId,
      name: subCollection.name,
      type: 'collection',
      image: subCollection.image,
    }))
  })), [data?.user.videoTypes]);

  return { list, loading };
};

interface QueryResult {
  user: {
    videoTypes: Array<{
      videoTypeId: number;
      name: string;
      subtypes: Array<{
        videoSubtypeId: number;
        name: string;
        image: string;
      }>;
    }>;
  };
}


export default VideoTutorials;
